const apiUri = 'https://apivigor.plataformassist.com.br/api' // ambiente de produção;
const relUri = 'vigor.plataformassist.com.br'
const imgFileLogin = 'vigor-assist.png'
const imgFileMenu = 'vigor-assist-branca.png'
const availableProjects = ['vigor-assist']
const ProjectID = '10'
const slug = 'milk-plan-report'

export const ENV_APP = {
    apiUri,
    relUri,
    imgFileLogin,
    imgFileMenu,
    availableProjects,
    ProjectID,
    slug,
}
